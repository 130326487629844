.dateAndTimeSelectPage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-tap-highlight-color: transparent;
    flex: 1;
    border-radius: 8px;
    min-height: 32rem;
}

.dateAndTimeSelectPage__content {
}

.dateAndTimeSelectPage_Close {
    -webkit-tap-highlight-color: initial;
}

.dateAndTimeSelectPage_Heading {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
}

.dateAndTimeSelectPage_reset {
    position: absolute;
    right: 2rem;
    top: 20px;
}

.dateAndTimeSelectPage_dateAndTime {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0;
}

.dateAndTimeSelectPage_dateAndTime__label {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
    color: var(--typographyBlack__MediumEmphasis);
}

.dateAndTimeSelectPage_dateAndTime__selectors {
    display: flex;
}

.dateAndTimeSelectPage_dateSelect {
    position: relative;
    width: 176px;
    height: 40px;
    margin-right: 8px;
    border: 1px solid;
    border-radius: 4px;
}

.dateAndTimeSelectPage_timeSelect {
    height: 40px;
    width: 116px;
    border: 1px solid;
    border-radius: 4px;
}

.dateAndTimeSelectPage_button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    -webkit-tap-highlight-color: initial;
}

.dateAndTimeSelectPage_Extendability {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 32px;
}

.dateAndTimeSelectPage_ExtendabilityWarning {
    color: var(--orange__dark);
}
